import {
  Box,
  Button,
  Center,
  Divider,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue as mode,
  LinkOverlay,
} from '@chakra-ui/react'
import * as React from 'react'
import {
  FaArrowRight,
} from 'react-icons/fa'
import Feature from './Feature'
import { StaticImage } from 'gatsby-plugin-image'
import { SecureByDefault } from 'components/icons/SecureByDefault'
import { PrivacyProtection } from 'components/icons/PrivacyProtection'
import { NeverYourPrivateKey } from 'components/icons/NeverYourPrivateKey'
import { Link } from 'gatsby'

export default function Security() {
  return (
    <Box as="section" bg={mode('gray.50', 'gray.800')} py="20">
      <Box
        maxW={{ base: 'xl', md: '7xl' }}
        mx="auto"
        px={{ base: '6', md: '8' }}
      >
        <Stack
          spacing={{ base: '4', lg: '20' }}
          direction={{ base: 'column', lg: 'row' }}
        >
          <Center flex="1" minH="26rem" maxW={{ lg: 'xl' }}>
            <StaticImage
              src="../../assets/images/OurStorySecurity.png"
              alt="OurStory"
            />
          </Center>
          <Box maxW={{ lg: 'lg' }}>
            <Heading
              size="2xl"
              mt="10"
              fontWeight="extrabold"
              letterSpacing="tight"
              lineHeight="normal"
              color={mode('gray.800', 'gray.50')}
            >
              Security is our top priority.
            </Heading>
            <Text fontSize="lg" mt="5" color={mode('gray.600', 'gray.400')}>
              We understand that when you use Walawong, you are entrusting us
              with access to your valuable digital assets. This is why we
              prioritise security and do not compromise on security practices.
            </Text>
            <Button
              className="group"
              mt="5"
              bgColor="brand.500"
              size="lg"
              px="8"
              fontWeight="bold"
              h="10"
              iconSpacing="3"
              rightIcon={
                <Box
                  as={FaArrowRight}
                  fontSize="sm"
                  transition="transform 0.2s"
                  _groupHover={{ transform: 'translateX(2px)' }}
                />
              }
            >
              <LinkOverlay as={Link} to={`/blog/security`} noOfLines={1}>
                Read more
              </LinkOverlay>
            </Button>
          </Box>
        </Stack>
        <Divider my="20" opacity={1} />
        <SimpleGrid
          columns={{ base: 1, md: 3 }}
          spacing={{ base: '12', md: '8' }}
        >
          <Feature title="Secure by default" icon={<SecureByDefault />}>
            All data is encrypted using the Advanced Encryption Standard (AES
            256). This is the same level of encryption banks and other
            institution use to secure their database. We also use advance key
            management system to secure all information.
          </Feature>
          <Feature title="Never your private key" icon={<NeverYourPrivateKey />}>
            Walawong will never at any time request for private key. We do not
            store nor require your private keys for any means. You should always
            keep your private key and never reveal it to any third party
            providers.
          </Feature>
          <Feature title="Privacy protection" icon={<PrivacyProtection />}>
            We collect as little personal information as possible. All accounts
            are linked to a UUID within our systems and not personally
            identifiable. Usernames and emails are encrypted and stored
            separately for privacy protection.
          </Feature>
        </SimpleGrid>
      </Box>
    </Box>
  )
}
