import { chakra, HTMLChakraProps } from '@chakra-ui/react'
import * as React from 'react'

interface LogoProps extends HTMLChakraProps<'svg'> {
    iconColor?: string
}

export const SecureByDefault = (props: LogoProps) => {
    const { ...rest } = props
    return (
        <chakra.svg
            width={49}
            height={12}
            fill="none"
            {...rest}
        >
            <path
                d="M24.222 16.49c-1.65 0-3 1.352-3 3.003v3.001h6v-3.001a3.01 3.01 0 00-3-3.002zM24.222 29.999a1.5 1.5 0 10-.001-3 1.5 1.5 0 000 3z"
                fill="#4532CC"
            />
            <path
                d="M46.764 19.85H42.36a18.928 18.928 0 00-1.93-4.86l3.458-3.461a1.456 1.456 0 000-2.064l-4.537-4.54a1.453 1.453 0 00-2.062 0l-3.365 3.367c-1.5-.919-3.15-1.632-4.893-2.1V1.453C29.03.647 28.384 0 27.577 0h-6.41c-.807 0-1.454.647-1.454 1.454v4.662c-1.8.441-3.496 1.154-5.052 2.073l-3-3.002a1.453 1.453 0 00-2.061 0l-4.518 4.55a1.456 1.456 0 000 2.064L8.1 14.81a18.502 18.502 0 00-2.034 5.038H1.68c-.806 0-1.452.647-1.452 1.454v6.416c0 .807.646 1.454 1.452 1.454h4.509a18.81 18.81 0 002.071 4.813L5.016 37.23a1.456 1.456 0 000 2.064l4.537 4.54c.572.572 1.49.572 2.062 0l3.337-3.34a18.672 18.672 0 004.583 1.867v4.184c0 .807.647 1.454 1.453 1.454H27.4c.806 0 1.453-.647 1.453-1.454v-4.174a18.625 18.625 0 004.8-1.98l3.477 3.48c.572.573 1.49.573 2.062 0l4.536-4.54a1.456 1.456 0 000-2.063l-3.458-3.462a18.64 18.64 0 001.968-4.634h4.537c.806 0 1.453-.647 1.453-1.454v-6.416a1.469 1.469 0 00-1.463-1.454zM33.22 31.5c0 1.66-1.34 3.002-3 3.002H18.224c-1.659 0-3-1.342-3-3.002v-6.004c0-1.66 1.341-3.002 3-3.002v-3.001a6.007 6.007 0 015.999-6.004 6.007 6.007 0 015.999 6.004v3.001c1.659 0 3 1.342 3 3.002V31.5z"
                fill="#4532CC"
            />
        </chakra.svg>
    )
}