import { chakra, HTMLChakraProps } from '@chakra-ui/react'
import * as React from 'react'

interface LogoProps extends HTMLChakraProps<'svg'> {
    iconColor?: string
}

export const NeverYourPrivateKey = (props: LogoProps) => {
    const { ...rest } = props
    return (
        <chakra.svg
            width={48}
            height={12}
            fill="none"
            {...rest}
        >
            <path
                d="M47.706 15.138c0-2.043-.402-4.024-1.189-5.895a15.103 15.103 0 00-3.243-4.811 15.105 15.105 0 00-4.811-3.244A15.005 15.005 0 0032.567 0a15.13 15.13 0 00-5.895 1.188 15.104 15.104 0 00-4.811 3.244 15.103 15.103 0 00-3.244 4.81 15.004 15.004 0 00-1.188 5.896c0 2.555.643 5.058 1.866 7.285L1.17 40.53c-.419.419-.551 1.056-.293 1.659.052.126.138.24.236.339l4.058 4.058c.098.098.213.178.34.236.602.258 1.24.132 1.658-.293L9.68 44.02l3.61 3.611c.098.098.213.178.34.235.602.259 1.24.132 1.659-.292l4-4.002c.099-.097.179-.212.236-.338.259-.603.132-1.24-.293-1.66l-3.553-3.553 9.598-9.604a15.114 15.114 0 007.286 1.865c2.043 0 4.024-.401 5.895-1.188a15.104 15.104 0 004.811-3.243 15.106 15.106 0 004.438-10.713zm-9.834 5.31a7.465 7.465 0 01-5.31 2.2 7.465 7.465 0 01-5.31-2.2 7.47 7.47 0 01-2.205-5.31c0-2.009.78-3.892 2.199-5.31a7.465 7.465 0 015.31-2.199c2.003 0 3.892.781 5.31 2.2a7.465 7.465 0 012.199 5.31 7.39 7.39 0 01-2.193 5.31z"
                fill="#4532CC"
            />
        </chakra.svg>
    )
}