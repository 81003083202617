import { chakra, HTMLChakraProps } from '@chakra-ui/react'
import * as React from 'react'

interface LogoProps extends HTMLChakraProps<'svg'> {
    iconColor?: string
}

export const PrivacyProtection = (props: LogoProps) => {
    const { ...rest } = props
    return (
        <chakra.svg
            width={49}
            height={12}
            fill="none"
            {...rest}
        >
            <path
                d="M24.227 30.003a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM24.227 16.505c-1.65 0-3 1.35-3 3v2.999h6v-3c0-1.65-1.35-3-3-3z"
                fill="#4532CC"
            />
            <path
                d="M46.277.298C45.086-.2 43.68-.058 42.648.663c-4.284 3.01-11.99 3.01-16.275 0-1.256-.88-3.046-.88-4.303 0-4.284 3.01-12 3.01-16.275 0-1.03-.721-2.447-.871-3.628-.365S.227 1.854.227 3.007v23.996c0 9.402 8.615 17.21 23.043 20.875.31.075.638.122.957.122.318 0 .646-.038.956-.122 14.428-3.665 23.044-11.473 23.044-20.875V3.007c0-1.153-.76-2.203-1.95-2.71zm-13.05 31.204c0 1.66-1.341 3-3 3h-12c-1.66 0-3-1.34-3-3v-5.999c0-1.659 1.34-3 3-3v-2.999a6.005 6.005 0 016-5.999c3.309 0 6 2.69 6 6v2.999c1.659 0 3 1.34 3 3v5.998z"
                fill="#4532CC"
            />
        </chakra.svg>
    )
}